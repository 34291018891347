import React from "react";
import styles from "./Dashboard.module.css";
import LeftSideNav from "./LeftSideNav";
import Binance from "../Resources/Binance.svg";
import Wazirx from "../Resources/Wazirx.svg";
import HuobiGlobal from "../Resources/HuobiGlobal.svg";
import Kucoin from "../Resources/Kucoin.svg";
import Ftx from "../Resources/FTX.svg";
import Coinbase from "../Resources/Coinbase.svg";
import Bitfinix from "../Resources/Bitfinex.svg";
import Kraken from "../Resources/kraken.svg";
import Crypto from "../Resources/Cryptocom.svg";
import BitBns from "../Resources/Bitbns.svg";

import { QueryClientProvider, QueryClient } from "react-query";
import DashboardLayout from "../DashboardLayout";
const queryClient = new QueryClient();

const ExchangeIntegrate = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <DashboardLayout>
        <div
          className={`${styles.exchangeDiv} flex xl:min-h-screen w-full items-center justify-center px-4 lg:px-0`}>
          <div
            className={`flex flex-col space-y-8 md:space-y-16 lg:space-y-12 xl:ml-[30px] bg-[#17181b] border-[1px] border-[#ffffff1a] rounded-[6px] px-[30px] py-[40px] md:p-[70px] lg:w-[950px]`}>
             <div className={`text-[#f2f2f2] text-[22px] md:text-[30px] md:leading-[40px] font-normal font-poppins`}>
              Exchange Integration
            </div>
            <div className="grid grid-cols-2 gap-y-8 gap-x-12 md:grid-cols-3   md:gap-y-10 md:gap-x-12">
              <div
                className={`flex items-center text-[15px] md:text-[20px] lg:text-[26px] lg:leading-[50px] font-normal font-poppins text-[#ffffff] `}
              >
                <img
                  src={Binance}
                  alt="Exchanges"
                  className={`w-[20px] h-[20px] md:h-[40px] md:w-[40px] lg:w-[40px] lg:h-[40px] mr-1.5 md:mr-3`}
                />{" "}
                Binance
              </div>
              <div
                className={`flex items-center text-[15px] md:text-[20px] lg:text-[26px] lg:leading-[50px] font-normal font-poppins text-[#ffffff] `}
              >
                <img
                  src={Wazirx}
                  alt="Exchanges"
                  className={`w-[20px] h-[20px] md:h-[40px] md:w-[40px] lg:w-[40px] lg:h-[40px] mr-1.5 md:mr-3`}
                />{" "}
                Wazirx
              </div>
              <div
                className={`flex items-center text-[15px] md:text-[20px] lg:text-[26px] lg:leading-[50px] font-normal font-poppins text-[#ffffff] `}
              >
                <img
                  src={HuobiGlobal}
                  alt="Exchanges"
                  className={`w-[20px] h-[20px] md:h-[40px] md:w-[40px] lg:w-[40px] lg:h-[40px] mr-1.5 md:mr-3`}
                />{" "}
                HuobiGlobal
              </div>
              <div
                className={`flex items-center text-[15px] md:text-[20px] lg:text-[26px] lg:leading-[50px] font-normal font-poppins text-[#ffffff] `}
              >
                <img
                  src={Kucoin}
                  alt="Kucoin"
                  className={`w-[20px] h-[20px] md:h-[40px] md:w-[40px] lg:w-[40px] lg:h-[40px] mr-1.5 md:mr-3`}
                />{" "}
                Kucoin
              </div>
              <div
                className={`flex items-center text-[15px] md:text-[20px] lg:text-[26px] lg:leading-[50px] font-normal font-poppins text-[#ffffff] `}
              >
                <img
                  src={Ftx}
                  alt="Ftx"
                  className={`w-[20px] h-[20px] md:h-[40px] md:w-[40px] lg:w-[40px] lg:h-[40px] mr-1.5 md:mr-3`}
                />{" "}
                FTX
              </div>
              <div
                className={`flex items-center text-[15px] md:text-[20px] lg:text-[26px] lg:leading-[50px] font-normal font-poppins text-[#ffffff] `}
              >
                <img
                  src={Coinbase}
                  alt="Coinbase"
                  className={`w-[20px] h-[20px] md:h-[40px] md:w-[40px] lg:w-[40px] lg:h-[40px] mr-1.5 md:mr-3`}
                />{" "}
                Coinbase
              </div>
              <div
                className={`flex items-center text-[15px] md:text-[20px] lg:text-[26px] lg:leading-[50px] font-normal font-poppins text-[#ffffff] `}
              >
                <img
                  src={Bitfinix}
                  alt="Bitfinix"
                  className={`w-[20px] h-[20px] md:h-[40px] md:w-[40px] lg:w-[40px] lg:h-[40px] mr-1.5 md:mr-3`}
                />{" "}
                Bitfinex
              </div>
              <div
                className={`flex items-center text-[15px] md:text-[20px] lg:text-[26px] lg:leading-[50px] font-normal font-poppins text-[#ffffff] `}
              >
                <img
                  src={Kraken}
                  alt="Kraken"
                  className={`w-[20px] h-[20px] md:h-[40px] md:w-[40px] lg:w-[40px] lg:h-[40px] mr-1.5 md:mr-3`}
                />{" "}
                Kraken
              </div>
              <div
                className={`flex items-center text-[15px] md:text-[20px] lg:text-[26px] lg:leading-[50px] font-normal font-poppins text-[#ffffff] `}
              >
                <img
                  src={Crypto}
                  alt="Crypto"
                  className={`w-[20px] h-[20px] md:h-[40px] md:w-[40px] lg:w-[40px] lg:h-[40px] mr-1.5 md:mr-3`}
                />{" "}
                Crypto.com
              </div>
              <div
                className={`flex items-center text-[15px] md:text-[20px] lg:text-[26px] lg:leading-[50px] font-normal font-poppins text-[#ffffff] `}
              >
                <img
                  src={BitBns}
                  alt="Bitbns"
                  className={`w-[20px] h-[20px] md:h-[40px] md:w-[40px] lg:w-[40px] lg:h-[40px] mr-1.5 md:mr-3`}
                />{" "}
                Bitbns
              </div>
            </div>
          </div>
        </div>
      </DashboardLayout>
    </QueryClientProvider>
  );
};
export default ExchangeIntegrate;
