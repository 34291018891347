import React, { useState, useEffect } from "react";
import styles from "../Home/Dashboard.module.css";
import styles1 from "../Login/login.module.css";
import styles2 from "./userDetails.module.css";
import { AiOutlineUser, AiFillLock, AiOutlineMail } from "react-icons/ai";
import axios from "axios";

const UserDetailsData = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [show, setShow] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [showMsg, setShowMsg] = useState("");
  const saved = localStorage.getItem("userDetails");
  const initialValue = JSON.parse(saved);
  const userDetails = initialValue && initialValue.response;

  const handleSubmit = () => {
    if (!password && !confirmPassword) {
      setShowMsg("Please Enter the password");
    }
    if (password !== confirmPassword) {
      setShowMsg("New Password and Confirm Pssword are not same");
    } else {
      axios
        .put("https://server.anshassociates.org/api/user", {
          email: userDetails && userDetails[1],
          password: password,
        })
        .then((res) => {
          setShowComponent(true);
          setShowMsg("You have successfully updated your password.");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (showComponent) {
      setShowComponent(true);
      const toRef = setTimeout(() => {
        setShowComponent(false);
        setPassword("");
        setOldPassword("");
        setConfirmPassword("");
        clearTimeout(toRef);
      }, 4000);
    }
  }, [showComponent]);

  return (
    <div
      className={`flex items-center xl:ml-[50px] flex-col  bg-[#17181b] border-[1px] border-[#000000]/10 rounded-[6px]  px-[20px] py-[20px] md:px-[60px] md:py-[60px] w-full lg:w-[950px]   lg:px-[110px] lg:py-[90px]`}
    >

      <div className=" w-full lg:max-w-[680px]">

     
 <div className={`text-[#f2f2f2] text-[24px] md:text-[30px] md:leading-[40px] font-normal font-poppins`}>
  User Details
  </div>
      {/* <div className={styles2.userSetDiv}>
            <div className={styles2.labelText}>Profile photo :</div>
            <div className={styles2.loginDiv}>
              <input
                type="file"
                value={username}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Id Number"
                className={`${styles2.logininput} ${styles2.inputFile}`}
              />
            </div>
  </div> */}
      <div className={`flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:items-center mt-[30px] `}>
        <div className={`${styles2.labelText} w-full lg:w-[25%]`}>Id Number :</div>
        <div
          className={`flex  items-center px-2 rounded-[6px] cursor-not-allowed bg-[#ffcf2c1a] border-[1px] border-[#ffffff]/20 `}
        >
          <AiOutlineUser className={`text-[#FFCF2C] w-5 h-5`} />
          <input
            type="text"
            value={userDetails && userDetails[3]}
            placeholder="Username"
            disabled
            className={`${styles2.logininput} w-full lg:w-[460px] `}
          />
        </div>
      </div>
      <div className={`flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:items-center mt-[30px]`}>
        <div className={`${styles2.labelText} w-full lg:w-[25%]`}>Email :</div>
        <div
          className={`flex  items-center px-2 rounded-[6px] cursor-not-allowed bg-[#ffcf2c1a] border-[1px] border-[#ffffff]/20`}
        >
          <AiOutlineMail className={`text-[#FFCF2C] w-5 h-5`} />
          <input
            type="text"
            value={userDetails && userDetails[1]}
            placeholder="Email"
            className={`${styles2.logininput} w-full lg:w-[460px] `}
            disabled
          />
        </div>
      </div>
      {show === true ? (
        <div>
          <div className={`flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:items-center mt-[30px]`}>
            <div className={`${styles2.labelText} w-full lg:w-[25%]`}> Old Password :</div>
            <div
              className={`flex  items-center px-2 rounded-[6px]   border-[1px] border-[#ffffff]/20`}
            >
              <AiFillLock className={`text-[#FFCF2C] w-5 h-5`} />
              <input
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                placeholder="Enter your old password here"
                className={`${styles2.logininput} w-full lg:w-[360px] `}
              />
            </div>
          </div>
          <div className={`flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:items-center mt-[30px]`}>
            <div className={`${styles2.labelText} w-full lg:w-[25%]`}>New Password :</div>
            <div
              className={`flex  items-center px-2 rounded-[6px]   border-[1px] border-[#ffffff]/20`}
            >
              <AiFillLock className={`text-[#FFCF2C] w-5 h-5`} />
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your new password here"
                className={`${styles2.logininput} w-full  lg:w-[360px] `}
              />
            </div>
          </div>
          <div className={`flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:items-center mt-[30px]`}>
            <div className={`${styles2.labelText} w-full lg:w-[25%]`}>Confirm Password :</div>
            <div
              className={`flex  items-center px-2 rounded-[6px]   border-[1px] border-[#ffffff]/20`}
            >
              <AiFillLock className={`text-[#FFCF2C] w-5 h-5`} />
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Re-Enter your new password here"
                className={`${styles2.logininput} w-full lg:w-[360px] `}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className={`flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:items-center mt-[30px]`}>
          <div className={`${styles2.labelText} w-full lg:w-[25%]`}>Password :</div>
          <div
            className={`relative flex  items-center px-2 rounded-[6px]   border-[1px] border-[#ffffff]/20`}
          >
            <AiFillLock className={`text-[#FFCF2C] w-5 h-5`} />
            <input
              type="password"
              value={userDetails && userDetails[2]}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              className={`${styles2.inputBox3} w-full lg:w-[460px]`}
              disabled
            />
            <div
              className={`absolute text-[14px] right-3 text-[#ffcf2c] font-medium font-poppins cursor-pointer`}
              onClick={() => setShow(true)}
            >
              Change
            </div>
          </div>
        </div>
      )}

      {showComponent === true ? (
        <div className={styles2.successMsg}>{showMsg}</div>
      ) : null}

      <div className={`flex space-x-8 justify-between w-full mt-[60px]`}>
        <button className={`flex items-center justify-center w-full bg-[#ffcf2c] rounded-[6px] text-[16px] text-[#000000] font-poppins font-medium px-4  h-[48px] `} onClick={handleSubmit}>
          {showComponent === true ? "Saving ........." : "Save Details"}
        </button>
        <button
          className={`flex items-center justify-center w-full bg-transparent rounded-[6px] text-[16px] text-[#ffffff] font-poppins font-medium px-4  h-[48px] border-[1px] border-[#ffffff]/20`}
          onClick={() => setShow(false)}
        >
          Cancel
        </button>
      </div>
      </div>
    </div>
  );
};

export default UserDetailsData;
