import React from "react";
import { cn } from "./Utils/utils";
import { Link,withRouter } from "react-router-dom";
import { logout } from "./store/actions/AuthActions";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import { connect, useDispatch } from "react-redux";
import {
  LogOutIcon,
  WalletIcon,
  RefreshCcwIcon,
  FileClockIcon,
  UserIcon,
  IndianRupeeIcon, 
  HomeIcon,
} from "lucide-react";


const Icons = {
  home: <HomeIcon className="mr-5 h-5 w-5" />,
  user: <UserIcon className="mr-5 h-5 w-5" />,
  deposit: <WalletIcon className="mr-5 h-5 w-5" />,
  withdrawal: <IndianRupeeIcon className="mr-5 h-5 w-5" />,
  exchange:<RefreshCcwIcon className="mr-5 h-5 w-5" />,
  history:<FileClockIcon className="mr-5 h-5 w-5" />

};

const sideBarNavItems = [
  {
    title: "Home",
    href: "/",
    path: "/",
    icon: "home",
  },
  {
    title: "User Details",
    href: "/user-details",
    path: "user-details",
    icon: "user",
  },
  {
    title: "Deposit",
    href: "/deposit",
    path: "deposit",
    icon: "deposit",
  },
  {
    title: "Withdrawal",
    href: "/withdrawal",
    path: "withdrawal",
    icon: "withdrawal",
  },
  {
    title: "Exchange",
    href: "/integrate-exchange",
    path: "integrate-exchange",
    icon: "exchange",
  },
  {
    title: "Transaction History",
    href: "/transaction-history",
    path: "transaction-history",
    icon: "history",
  },
];

const DashboardNav = (props) => {
  
  const path = window.location.pathname;

  const dispatch = useDispatch();

  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logout(props.history));
  };

  const saved = localStorage.getItem("userDetails");
  const initialValue = JSON.parse(saved);
  const userDetails = initialValue && initialValue.response;

  var intials;
  var fullName = userDetails && userDetails[4];
  intials =
    fullName &&
    fullName
      .split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();

  return (
    <nav className="  bg-[#17181b] grid items-start gap-y-2 ">
      <div className="flex flex-col  border-t-[1px] border-[#ffffff]/20 w-full  py-5">
        <div className="flex   items-center justify-center w-full ">
          <div className="flex flex-col space-y-3 items-center   text-white text-center   ">
            <div className="flex  font-medium text-3xl items-center justify-center rounded-full border-[2px] border-[#ffffff]/30 w-[70px] h-[70px] p-1">
              {intials}
            </div>
            <div className="flex flex-col items-center">
              <span className="text-[20px] text-[#F2F2F2] font-poppins font-medium">
              {userDetails && userDetails[4]}
              </span>
              <span className="text-[15px] text-[#D9D9D9]/80 font-poppins font-normal mt-1">
              {userDetails && userDetails[1]}
              </span>
            </div>
          </div>
        </div>
      </div>

      {sideBarNavItems.map((item, index) => {
        const Icon = Icons[item.icon];

        return (
          item.href && (
            <div key={index}>
              <Link key={index} to={item.disabled ? "/" : item.href}>
                <div
                  className={cn(
                    "group  text-sm border-l-8  hover:bg-[#ffcf2c1a] hover:text-gray-300 text-[18px] text-[#ffffff]/50 font-normal font-poppins leading-[22px] flex items-center px-[30px] py-[14px]",
                    path === item.href
                      ? "bg-[#ffcf2c1a] border-l-8 border-[#ffcf2c] text-[#ffffff]"
                      : "border-l-8 border-transparent bg-transparent",
                    item.disabled && "cursor-not-allowed opacity-80"
                  )}
                >
                  {Icon}
                  <span>{item.title}</span>
                  
                </div>
              </Link>
            </div>
          )
        );
      })}

      <div
        onClick={onLogout}
        className={cn(
          "group cursor-pointer text-sm border-l-4 border-transparent bg-transparent hover:bg-[#ffcf2c1a] hover:text-gray-300 text-[18px] text-[#ffffff]/50 font-normal font-poppins leading-[22px] flex items-center px-[30px] py-[14px]"
        )}
      >
        <LogOutIcon className="h-5 w-5 text-[#ffffff]/50 group-hover:text-gray-300  mr-5" />
        <span>Logout</span>
      </div>

      
    </nav>
  );
};

const mapStateToProps = (state) => {
    return {
      isAuthenticated: isAuthenticated(state),
    };
  };

export default withRouter(connect(mapStateToProps)(DashboardNav));
