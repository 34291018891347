import React, { useState, useEffect } from "react";
import styles from "../Login/login.module.css";
import styles2 from "../UserDetails/userDetails.module.css";
import Redefining from "../Resources/Redefine_Results.svg";
import { AiOutlineMail } from "react-icons/ai";
import axios from "axios";

const ForgotPassowrd = () => {
  const [email, setEmail] = useState("");
  const [showMsg, setShowMsg] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const handleSubmit = () => {
    if (!email) {
      setError("Please Enter the email");
    } else
      axios
        .post("https://server.anshassociates.org/send-mail", {
          email: email,
        })
        .then((res) => {
          setShow(true);
          setShowMsg("Email has been sent to your email");
        })
        .catch((err) => {
          setErrorShow(true);
          setError(err.response.data.msg);
        });
  };
  useEffect(() => {
    if (show) {
      setShow(true);
      const toRef = setTimeout(() => {
        setShow(false);
        setEmail("");
        clearTimeout(toRef);
      }, 4000);
    }
  }, [show]);

  useEffect(() => {
    if (errorShow) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setErrorShow(false);
        setEmail("");
        clearTimeout(toRef);
      }, 4000);
    }
  }, [errorShow]);
  return (
    <div
      className={`flex flex-col items-center space-y-16 lg:space-y-0  lg:flex-row min-h-screen w-full lg:items-center justify-center px-6 md:px-10 lg:px-0`}
    >
      <div className={`flex flex-row-reverse items-center justify-center lg:space-x-0 lg:flex-col lg:items-start lg:w-[50%] `}>
        <div className="flex flex-col justify-center ml-6 lg:ml-0">
          <div
            className={`text-[32px] md:text-[56px] leading-[38px] md:leading-[60px] lg:text-[92px] lg:leading-[89px] font-bold font-poppins text-[#ffffff] `}
          >
            Redefining
          </div>
          <div
            className={`text-[32px] md:text-[56px] leading-[38px] md:leading-[60px] lg:text-[92px] lg:leading-[89px] font-bold font-poppins text-[#ffcf2c]`}
          >
            Returns
          </div>
        </div>
        <div className={`lg:pl-[30px] lg:pt-[60px]`}>
          {" "}
          <img
            src={Redefining}
            alt="Redefining"
            className="w-[100px] md:w-[160px] lg:w-[220px]"
          />
        </div>
      </div>

      <div className={`w-full md:w-auto`}>
        <div
          className={` ${styles.loginBackground} w-full  flex flex-col p-[20px] md:p-[60px]`}
        >
          <div
            className={`w-full flex items-center justify-center text-[28px] lg:text-[50px] lg:leading-[48px] font-bold font-poppins text-[#ffffff]`}
          >
            Ansh Associates
          </div>
          <div
            className={`text-center text-[20px] mt-5 lg:text-[28px] lg:leading-[24px] font-medium lg:mt-[30px] text-[#ffffff]`}
          >
            Forgot your Password?
          </div>
          <div
            className={`text-center text-[11px] mt-2 lg:text-[14px] lg:leading-[32px] font-normal lg:mt-[6px] text-[#ffffffcc]`}
          >
            Don’t worry. We’ll send you an email to reset your password.
          </div>
          <div
            className={`flex items-center mt-[40px] bg-[#ffffff0a] rounded-[6px]`}
          >
            <AiOutlineMail className={`ml-4 w-5 h-5  text-[#FFCF2C]`} />
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              className={styles.logininput}
            />
          </div>
          {errorShow === true ? (
            <div className={styles.errorMsg}>{error}</div>
          ) : null}
          {show === true ? (
            <div className={styles2.successMsg}>{showMsg}</div>
          ) : null}
          <button className={styles.loginButton} onClick={handleSubmit}>
            {show === true ? "Sending......." : "Send"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassowrd;
