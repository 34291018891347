import React, { useState, useEffect } from "react";
import styles from "./login.module.css";
import Redefining from "../Resources/Redefine_Results.svg";
import { Link } from "react-router-dom";
import { AiOutlineUser, AiFillLock } from "react-icons/ai";
import { connect, useDispatch } from "react-redux";
import {
  loginAction,
  loginFailedAction,
} from "../store/actions/AuthActions.js";

const Login = (props) => {
  const dispatch = useDispatch();
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (props.errorMessage != null) {
      setError(props.errorMessage);
      loginFailedAction(dispatch);
    }
  }, [props.errorMessage, dispatch]);

  // handle button click of login form
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    if (!username.length || !password.length) {
      setError("UserName and password are required!");
      setLoading(false);
    } else {
      setError(null);
      await dispatch(loginAction(username, password));
      setLoading(true);
    }
  };

  useEffect(() => {
    if (loading) {
      setLoading(true);
      const toRef = setTimeout(() => {
        setLoading(false);
        clearTimeout(toRef);
      }, 4000);
    }
  }, [loading]);

  return (
    <div className={`flex flex-col items-center  space-y-16 lg:space-y-0  lg:flex-row min-h-screen w-full lg:items-center justify-center px-6 md:px-10 lg:px-0`}>
      <div className={`flex flex-row-reverse items-center justify-center lg:space-x-0 lg:flex-col lg:items-start lg:w-[50%] `}>
        <div className="flex flex-col justify-center ml-6 lg:ml-0">
          <div
            className={`text-[32px] md:text-[56px] leading-[38px] md:leading-[60px] lg:text-[92px] lg:leading-[89px] font-bold font-poppins text-[#ffffff] `}
          >
            Redefining
          </div>
          <div
            className={`text-[32px] md:text-[56px] leading-[38px] md:leading-[60px] lg:text-[92px] lg:leading-[89px] font-bold font-poppins text-[#ffcf2c]`}
          >
            Returns
          </div>
        </div>
        <div className={`lg:pl-[30px] lg:pt-[60px]`}>
          {" "}
          <img
            src={Redefining}
            alt="Redefining"
            className="w-[100px] md:w-[160px] lg:w-[220px]"
          />
        </div>
      </div>
      <div className={`w-full md:w-auto`}>
        <div className={` ${styles.loginBackground} w-full  flex flex-col p-[20px] md:p-[60px]`}>
          <div className={`w-full flex items-center justify-center text-[24px] lg:text-[50px] lg:leading-[48px] font-bold font-poppins text-[#ffffff]  `}>Ansh Associates</div>
          <div className={`flex items-center mt-[40px] bg-[#ffffff0a] rounded-[6px]`}>
            <AiOutlineUser
              
              className={`ml-4 w-5 h-5  text-[#FFCF2C]`}
            />
            <input
              type="text"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              placeholder="username"
              className={styles.logininput}
            />
          </div>
          <div className={`flex items-center mt-[40px] bg-[#ffffff0a] rounded-[6px]`}>
            <AiFillLock
               className={`ml-4 w-5 h-5  text-[#FFCF2C]`}
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="password"
              className={styles.logininput}
            />
          </div>
          <div className="flex flex-row justify-end w-full ">
            <div className={styles.checkbox}></div>
            <Link to="/forgot-password" className={styles.forgotText}>
              Forgot your password?
            </Link>
          </div>

          {error ? <div className={styles.errorMsg}>{error}</div> : null}

          {loading === true ? (
            <button className={styles.loginButton}>Authenticating...</button>
          ) : (
            <button className={styles.loginButton} onClick={handleSubmit}>
              Login
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    errorMessage: state.auth.errorMessage,
  };
};

export default connect(mapStateToProps)(Login);
