import React from "react";
import styles from "../Home/Dashboard.module.css";
import LeftSideNav from "../Home/LeftSideNav";
import WithdrawalData from "./WithdrawalData";
import { QueryClientProvider, QueryClient } from "react-query";
import DashboardLayout from "../DashboardLayout";
const queryClient = new QueryClient();

const Withdrawal = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <DashboardLayout>
      <div className={`${styles.exchangeDiv} flex flex-col xl:min-h-screen w-full lg:items-center justify-center px-3 md:px-10 lg:px-0`}>
          <WithdrawalData />
        </div>
      </DashboardLayout>
    </QueryClientProvider>
        
  );
};

export default Withdrawal;
