import React, { useState, useEffect } from "react";
import { UseDashboarddata } from "../Utils/AllData";
import axios from "axios";


const DashboardData = () => {
  const { data } = UseDashboarddata();
  const dashboardData = data && data.data.values;

  const [state, setState] = useState(0);
  useEffect(() => {
    var config = {
      method: "get",
      url: "https://api.currencyfreaks.com/latest?apikey=6ae9cc6527a040d18a58f9fe3a66496f",
      headers: {},
    };

    axios(config)
      .then(function (response) {
        setState(response.data.rates.INR);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  return (
    <div>
      {dashboardData &&
        dashboardData.map((ele, i) => {
          const item = ele[3].replace("₮", "");
          const item2 = ele[1].replace("₮", "");
          const checkValue = Math.sign(item);

          console.log("item",item)

          return (
            <div className={`flex space-x-3 md:space-x-10 mt-4 md:mt-8 xl:mt-4`} key={i}>
              <div className={`flex flex-col md:space-y-5 bg-[#17181b] border-[1px] border-[#ffffff]/20 rounded-[6px] px-[8px] py-[8px] md:px-[40px] md:py-[20px]`}>
                <div className={`text-[13px] text-[#ffffff]/80 font-medium font-poppins md:text-[20px] md:leading-[22px]`}>
                  Invested Amount ( USDT / INR )
                </div>
                <div className={`text-[16px] text-[#ffffff]/80 font-semibold font-poppins md:text-[30px] md:leading-[22px] mt-2`}>
                  {" "}
                  {ele[1]} / ₹
                  {state && state
                    ? parseInt(parseFloat(item2) * parseFloat(state && state))
                    : null}
                </div>
              </div>

              <div className={`flex flex-col md:space-y-5 bg-[#17181b] border-[1px] border-[#ffffff]/20 rounded-[6px] px-[8px] py-[8px] md:px-[40px] md:py-[20px]`}>
                <div className={`text-[13px] text-[#ffffff]/80 font-medium font-poppins md:text-[20px] md:leading-[22px]`}>Profit & Loss</div>
                <div
                  className={
                    parseFloat(item) > 0
                      ? `text-[16px]  text-[#18b13f] font-semibold font-poppins md:text-[30px] md:leading-[22px] mt-2`
                      : parseFloat(item) < 0
                      ? `text-[16px]  text-[#eb323f] font-semibold font-poppins md:text-[30px] md:leading-[22px] mt-2`
                      : `text-[16px]  text-[#ffffff]/80 font-semibold font-poppins md:text-[30px] md:leading-[22px] mt-2`
                  }
                >
                  {ele[3]}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};
export default DashboardData;
