import React from "react";
import styles from "../Home/Dashboard.module.css";
import LeftSideNav from "../Home/LeftSideNav";
import UserDetailsData from "./userDetailsData";
import { QueryClientProvider, QueryClient } from "react-query";
import DashboardLayout from "../DashboardLayout";
const queryClient = new QueryClient();
const UserDetails = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <DashboardLayout>
        <div className={`${styles.exchangeDiv} flex flex-col xl:min-h-screen w-full items-center justify-center px-3 md:px-10 lg:px-0`}>
          <UserDetailsData />
        </div>
      </DashboardLayout>
    </QueryClientProvider>
  );
};

export default UserDetails;
