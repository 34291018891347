import React, { useEffect, useState } from "react";
import DashboardNav from "./DashboardNav";
import { MenuIcon } from "lucide-react";
import MobileNavBar from "./MobileNavBar";

export default function DashboardLayout({ children }) {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <div className="relative flex flex-col xl:grid xl:grid-cols-10 min-h-screen w-full ">
      {/* Dashboard Side Bar  */}
      <div className="sticky top-0 hidden xl:flex col-span-2 h-screen bg-[#17181b] ">
        <aside className=" max-h-screen  w-full flex-col lg:flex   bg-[#17181b] border-r-[1px] border-[#454646]/40">
          <div className="flex flex-row space-x-6 items-center  px-8 py-4">
            <div className="">
              {/* <img src={Logo} alt="Logo" width={50} className="rounded-md" /> */}
            </div>
            <div className="text-[24px] font-medium font-poppins text-[#f2f2f2]  ">
              Ansh Associates
            </div>
          </div>

          <DashboardNav />
        </aside>
      </div>

      <div className="sticky top-0 z-50 xl:hidden flex justify-between h-[60px] md:h-[90px]  bg-[#17181b] border-b-[1px] border-[#454646]/60 px-4 ">
        <div className="flex items-center space-x-2">
          
          <div className="text-[20px] md:text-[34px] font-medium font-poppins text-[#f2f2f2] leading-[20px] ">
            Ansh Associates
          </div>
        </div>

        <div
          onClick={toggleMobileNav}
          className=" flex items-center  xl:hidden text-white  cursor-pointer"
        >
          <MenuIcon className="h-[36px] w-[36px] md:h-12 md:w-12 text-white " />
        </div>
        {isMobileNavOpen && (
          <MobileNavBar isOpen={isMobileNavOpen} onClose={toggleMobileNav} />
        )}
      </div>

      {/* Main Dashboard  */}
      <main className="  flex flex-1 lg:col-span-8 lg:flex   overflow-hidden  transition duration-500 ease-in">
        {children}
      </main>
    </div>
  );
}
