import { useQuery, useQueryClient } from "react-query";
import axios from "axios";

const fetchTradeHistory = () => {
  return axios.get(
    `https://server.anshassociates.org/api/user/data/trade-history`
  );
};

const fetchUsdtTradeHistory = () => {
  return axios.get(
    `https://server.anshassociates.org/api/user/data/usdt/usdt-trade-history`
  );
};

const fetchMarqueeData = () => {
  return axios.get(`https://server.anshassociates.org/api/user/marquee/text`);
};

export const UseTradeHistoryData = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(["tradeHistory", hero], fetchTradeHistory, {
    initialData: () => {
      const data = queryClient
        .getQueryData("trade-heroes")
        ?.data?.find((data) => data.id === parseInt(hero));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseUsdtTradeHistoryData = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(["usdtTradeHistory", hero], fetchUsdtTradeHistory, {
    initialData: () => {
      const data = queryClient
        .getQueryData("trade-heroes")
        ?.data?.find((data) => data.id === parseInt(hero));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseMarqueeText = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(["marqueeText", hero], fetchMarqueeData, {
    initialData: () => {
      const data = queryClient
        .getQueryData("trade-heroes")
        ?.data?.find((data) => data.id === parseInt(hero));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};
