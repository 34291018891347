import React, { useState, useEffect } from "react";
import styles from "../Home/Dashboard.module.css";
import styles2 from "./userDetails.module.css";
import axios from "axios";
import Moment from "moment";

import { motion } from "framer-motion";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";

const style = {
  InputBox: "rounded-lg box-border border-[1px] border-solid border-[#d3d3ce] ",

  selectDiv:
    "flex items-center px-2 py-2  text-[14px] rounded-lg mx-3 md-3 text-neutral-300  hover:bg-[#ffcf2c]/10 hover:text-white/85  ease-linear duration-150 cursor-pointer",
};
const DepositData = () => {
  const [amount, setAmount] = useState("₮" + " ");
  const [walletAddress, setwalletAddress] = useState("");
  const [network, setnetwork] = useState("");
  const [selectCurrency, setselectCurrency] = useState("");
  const [showMsg, setShowMsg] = useState("");
  const [showComponent, setShowComponent] = useState(false);
  const [error, setError] = useState("");
  const [errorShow, setErrorShow] = useState(false);
  const saved = localStorage.getItem("userDetails");
  const initialValue = JSON.parse(saved);
  const userDetails = initialValue && initialValue.response;

  const currentDate = new Date();
  const newDate = Moment(String(currentDate)).format("MM-DD-YYYY");

  const handleSubmit = () => {
    if (!amount || !walletAddress || !network || !selectCurrency) {
      setErrorShow(true);
      setError("All Fields are required");
    } else {
      axios
        .post("https://server.anshassociates.org/api/user/deposit", {
          username: userDetails && userDetails[0],
          email: userDetails && userDetails[1],
          id_number: userDetails && userDetails[3],
          amount: amount.replace("₮", ""),
          wallet_address: walletAddress,
          network: network,
          select_currency: selectCurrency,
          date: newDate,
        })
        .then((res) => {
          setShowComponent(true);
          setShowMsg("You have successfully deposited your amount.");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (showComponent) {
      setShowComponent(true);
      const toRef = setTimeout(() => {
        setShowComponent(false);
        setAmount("₮", "");
        setselectCurrency("");
        setnetwork("");
        setwalletAddress("");
        clearTimeout(toRef);
      }, 4000);
    }
  }, [showComponent]);

  useEffect(() => {
    if (errorShow) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setErrorShow(false);
        clearTimeout(toRef);
      }, 2000);
    }
  }, [errorShow]);

  const [show, setShow] = useState(false);
  const [showNetwork, setShowNetwork] = useState(false);
  const [showWalletAddress, setShowWalletAddress] = useState(false);
  const [show1, setShow1] = useState(false);

  const [walletAddressList, setWalletAddressList] = useState();

  const getWalletAddressList = () => {
    axios
      .get("https://server.anshassociates.org/api/common")
      .then((res) => {
        setWalletAddressList(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getWalletAddressList();
  }, []);
  
  return (
    
      <div   className={`flex items-center xl:ml-[50px] flex-col  bg-[#17181b] border-[1px] border-[#000000]/10 rounded-[6px]  px-[20px] py-[20px] md:px-[60px] md:py-[60px] w-full lg:w-[950px]   lg:px-[110px] lg:py-[90px]`}>
        
        <div className=" w-full lg:max-w-[680px]">
        <div className={`text-[#f2f2f2] text-[24px] md:text-[30px] md:leading-[40px] font-normal font-poppins`}>Deposit</div>
        
        <div className={`flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:items-center mt-[20px] lg:mt-[30px]`}>
          <div className={`${styles2.labelText} w-full lg:w-[25%]`}>Select Currency :</div>
          <div className={`relative flex  items-center px-2 rounded-[6px]   border-[1px] border-[#ffffff]/20`}>
            <div
              onClick={() => {
                setShow(!show);
              }}
              className={`${styles2.inputBox2} flex justify-between w-full lg:w-[460px] `}
            >
              {selectCurrency === "BTC" ? (
                <div className={`${styles.dropDownSelection}`}> Bitcoin(BTC)</div>
              ) : selectCurrency === "USDT" ? (
                <div className={styles.dropDownSelection}>USDT</div>
              ) : (
                <div className={styles.dropDownSelection}>Select Option</div>
              )}

              <div
                className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
              >
                {show ? (
                  <AiFillCaretUp size={20} color="#fff" />
                ) : (
                  <AiFillCaretDown size={20} color="#fff" />
                )}
              </div>
            </div>
            {show && (
              <motion.div
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, type: "spring" }}
                exit={{ y: -50, opacity: 0 }}
                className={`absolute right-0 top-12 bg-[#17181b] flex flex-col w-[100%] pt-2 pb-2 z-10 ${styles.dropdownBox2}`}
              >
                <div
                  className={`${style.selectDiv} pl-3 font-medium`}
                  onClick={() => {
                    setselectCurrency("BTC");
                    setShow(!show);
                  }}
                >
                  Bitcoin(BTC)
                </div>
                <div
                  className={`${style.selectDiv} pl-3 font-medium`}
                  onClick={() => {
                    setselectCurrency("USDT");
                    setShow(!show);
                  }}
                >
                  USDT
                </div>
              </motion.div>
            )}
          </div>
        </div>

        <div className={`flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:items-center mt-[20px] lg:mt-[30px]`}>
          <div className={`${styles2.labelText} w-full lg:w-[25%]`}>Network :</div>
          <div className={`relative flex  items-center px-2 rounded-[6px]   border-[1px] border-[#ffffff]/20`}>
            {selectCurrency === "BTC" ? (
              <>
                <div
                  onClick={() => {
                    setShowNetwork(!showNetwork);
                  }}
                  className={`${styles2.inputBox2} flex justify-between w-full lg:w-[460px] `}
                >
                  {network === "BTC" ? (
                    <div className={styles.dropDownSelection}> BTC</div>
                  ) : (
                    <div className={styles.dropDownSelection}>
                      Select Option
                    </div>
                  )}

                  <div
                    className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                  >
                    {show ? (
                      <AiFillCaretUp size={20} color="#fff" />
                    ) : (
                      <AiFillCaretDown size={20} color="#fff" />
                    )}
                  </div>
                </div>
                {showNetwork && (
                  <motion.div
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, type: "spring" }}
                    exit={{ y: -50, opacity: 0 }}
                    className={`absolute right-0 top-12 bg-[#17181b] flex flex-col w-[100%] pt-2 pb-2 z-10 ${styles.dropdownBox2}`}
                  >
                    <div
                      className={`${style.selectDiv} pl-3 font-medium`}
                      onClick={() => {
                        setnetwork("BTC");
                        setShowNetwork(!showNetwork);
                      }}
                    >
                      Bitcoin(BTC)
                    </div>
                  </motion.div>
                )}
              </>
            ) : (
              <>
                <div
                  onClick={() => {
                    setShowNetwork(!showNetwork);
                  }}
                  className={`${styles2.inputBox2}  flex justify-between w-full lg:w-[460px] `}
                >
                  {network === "TRON20" ? (
                    <div className={styles.dropDownSelection}> TRON20</div>
                  ) : network === "BEP2" ? (
                    <div className={styles.dropDownSelection}>BEP2</div>
                  ) : (
                    <div className={styles.dropDownSelection}>
                      Select Option
                    </div>
                  )}

                  <div
                    className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                  >
                    {show ? (
                      <AiFillCaretUp size={20} color="#fff" />
                    ) : (
                      <AiFillCaretDown size={20} color="#fff" />
                    )}
                  </div>
                </div>
                {showNetwork && (
                  <motion.div
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, type: "spring" }}
                    exit={{ y: -50, opacity: 0 }}
                    className={`absolute right-0 top-12 bg-[#17181b] flex flex-col w-[100%] pt-2 pb-2 z-10 ${styles.dropdownBox2}`}
                  >
                    <div
                      className={`${style.selectDiv} pl-3 font-medium`}
                      onClick={() => {
                        setnetwork("TRON20");
                        setShowNetwork(!showNetwork);
                      }}
                    >
                      TRON20
                    </div>
                    <div
                      className={`${style.selectDiv} pl-3 font-medium`}
                      onClick={() => {
                        setnetwork("BEP2");
                        setShowNetwork(!showNetwork);
                      }}
                    >
                      BEP2
                    </div>
                  </motion.div>
                )}
              </>
            )}
          </div>
        </div>
        
        <div className={`flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:items-center mt-[20px] lg:mt-[30px]`}>
          <div className={`${styles2.labelText} w-full lg:w-[25%]`}>Wallet Address :</div>
          <div className={`relative flex  items-center px-2 rounded-[6px]   border-[1px] border-[#ffffff]/20`}>
            {network === "BTC" ? (
              <>
                <div
                  onClick={() => {
                    setShowWalletAddress(!showWalletAddress);
                  }}
                  className={`${styles2.inputBox2} flex justify-between w-full lg:w-[365px] `}
                >
                  {walletAddress ===
                  `${walletAddressList && walletAddressList[0][0]}` ? (
                  <div className={`${styles.dropDownSelection} w-[160px] truncate md:w-auto`}>
                      {walletAddressList && walletAddressList[0][0].length > 10
                        ? walletAddressList &&
                          walletAddressList[0][0]
                        : walletAddressList && walletAddressList[0][0]}
                    </div>
                  ) : (
                    <div className={styles.dropDownSelection}>
                      Select Option
                    </div>
                  )}

                  <div
                    className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                  >
                    {showWalletAddress ? (
                      <AiFillCaretUp size={20} color="#fff" />
                    ) : (
                      <AiFillCaretDown size={20} color="#fff" />
                    )}
                  </div>
                </div>
                {showWalletAddress && (
                  <motion.div
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, type: "spring" }}
                    exit={{ y: -50, opacity: 0 }}
                    className={`absolute right-0 top-12 bg-[#17181b] flex flex-col w-[100%] pt-2 pb-2 z-10 ${styles.dropdownBox2}`}
                  >
                    <div
                      className={`${style.selectDiv} truncate pl-3 font-medium`}
                      onClick={() => {
                        setwalletAddress(
                          walletAddressList && walletAddressList[0][0]
                        );
                        setShowWalletAddress(!showWalletAddress);
                      }}
                    >
                      {walletAddressList && walletAddressList[0][0].length > 10
                        ? walletAddressList &&
                          walletAddressList[0][0]
                        : walletAddressList && walletAddressList[0][0]}
                    </div>
                  </motion.div>
                )}
              </>
            ) : network === "TRON20" ? (
              <>
                <div
                  onClick={() => {
                    setShowWalletAddress(!showWalletAddress);
                  }}
                  className={`${styles2.inputBox2}  flex justify-between w-full lg:w-[365px] `}
                >
                  {walletAddress ===
                  `${walletAddressList && walletAddressList[0][1]}` ? (
                    <div className={`${styles.dropDownSelection} w-[160px] truncate md:w-auto `}>
                      {walletAddressList && walletAddressList[0][1].length > 10
                        ? walletAddressList &&
                          walletAddressList[0][1]
                        : walletAddressList && walletAddressList[0][1]}
                    </div>
                  ) : (
                    <div className={styles.dropDownSelection}>
                      Select Option
                    </div>
                  )}

                  <div
                    className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                  >
                    {showWalletAddress ? (
                      <AiFillCaretUp size={20} color="#fff" />
                    ) : (
                      <AiFillCaretDown size={20} color="#fff" />
                    )}
                  </div>
                </div>
                {showWalletAddress && (
                  <motion.div
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, type: "spring" }}
                    exit={{ y: -50, opacity: 0 }}
                    className={`absolute right-0 top-12 bg-[#17181b] flex flex-col w-[100%] pt-2 pb-2 z-10 ${styles.dropdownBox2}`}
                  >
                    <div
                      className={`${style.selectDiv} truncate pl-3 font-medium`}
                      onClick={() => {
                        setwalletAddress(
                          walletAddressList && walletAddressList[0][1]
                        );
                        setShowWalletAddress(!showWalletAddress);
                      }}
                    >
                      {walletAddressList && walletAddressList[0][1].length > 10
                        ? walletAddressList &&
                          walletAddressList[0][1]
                        : walletAddressList && walletAddressList[0][1]}
                    </div>
                  </motion.div>
                )}
              </>
            ) : (
              <>
                <div
                  onClick={() => {
                    setShowWalletAddress(!showWalletAddress);
                  }}
                  className={`${styles2.inputBox2} flex  justify-between w-full lg:w-[365px] `}
                >
                  {walletAddress ===
                  `${walletAddressList && walletAddressList[0][2]}` ? (
                    <p className={`${styles.dropDownSelection}  w-[160px] truncate md:w-auto`}>
                      {walletAddressList && walletAddressList[0][2].length > 10
                        ? walletAddressList &&
                          walletAddressList[0][2]
                        : walletAddressList && walletAddressList[0][2]}
                    </p>
                  ) : (
                    <div className={styles.dropDownSelection}>
                      Select Option
                    </div>
                  )}

                  <div
                    className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                  >
                    {showWalletAddress ? (
                      <AiFillCaretUp size={20} color="#fff" />
                    ) : (
                      <AiFillCaretDown size={20} color="#fff" />
                    )}
                  </div>
                </div>
                {showWalletAddress && (
                  <motion.div
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, type: "spring" }}
                    exit={{ y: -50, opacity: 0 }}
                    className={`absolute right-0 top-12 bg-[#17181b] flex flex-col w-[100%] pt-2 pb-2 z-10 ${styles.dropdownBox2}`}
                  >
                    <div
                      className={`${style.selectDiv} truncate pl-3 font-medium`}
                      onClick={() => {
                        setwalletAddress(
                          walletAddressList && walletAddressList[0][2]
                        );
                        setShowWalletAddress(!showWalletAddress);
                      }}
                    >
                      {walletAddressList && walletAddressList[0][2].length > 10
                        ? walletAddressList &&
                          walletAddressList[0][2]
                        : walletAddressList && walletAddressList[0][2]}
                    </div>
                  </motion.div>
                )}
              </>
            )}
            <div
            className="w-[80px] text-center text-black bg-[#FFCF2C] py-1.5 px-2 lg:ml-4 rounded-lg font-semibold font-poppins text-[16px] cursor-pointer"
            onClick={() => {
              network === "BTC"
                ? navigator.clipboard.writeText(
                    walletAddressList && walletAddressList[0][0]
                  )
                : network === "TRON20"
                ? navigator.clipboard.writeText(
                    walletAddressList && walletAddressList[0][1]
                  )
                : navigator.clipboard.writeText(
                    walletAddressList && walletAddressList[0][2]
                  );
              setShow1(true);
              setTimeout(() => {
                setShow1(false);
              }, 5000);
            }}
          >
            {show1 ? (
              <div className={"text-neutral-900 font-poppins text-[14px] font-semibold"}>
                Copied!
              </div>
            ) : (
              <div className={"text-neutral-900 font-poppins text-[14px] font-semibold"}>
                Copy
              </div>
            )}
          </div>
          </div>
          
        </div>

        <div className={`flex flex-col space-y-2 lg:space-y-0 lg:flex-row lg:items-center mt-[20px] lg:mt-[30px]`}>
          <div className={`${styles2.labelText} w-full lg:w-[25%]`}>Amount :</div>
          <div  className={`flex  items-center px-2 rounded-[6px]   border-[1px] border-[#ffffff]/20`}>
            <input
              type="text"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Amount"
              className={`${styles2.logininput} w-full lg:w-[460px] `}
            />
          </div>
        </div>


        {errorShow === true ? (
          <div className={`${styles2.successMsg} ${styles2.redTextColor}`}>
            {error}
          </div>
        ) : null}

        {showComponent === true ? (
          <div className={styles2.successMsg}>{showMsg}</div>
        ) : null}

        <div className={`flex space-x-8 justify-between w-full mt-[40px] lg:mt-[60px]`}>
          <button
            className={`flex items-center justify-center w-full bg-[#ffcf2c] rounded-[6px] text-[16px] text-[#000000] font-poppins font-medium px-4  h-[48px] `}
            onClick={handleSubmit}
          >
            {showComponent === true ? "Saving ........." : "Save"}
          </button>
          <button
            className={`flex items-center justify-center w-full bg-transparent rounded-[6px] text-[16px] text-[#ffffff] font-poppins font-medium px-4  h-[48px] border-[1px] border-[#ffffff]/20`}
          >
            Cancel
          </button>
        </div>
      </div>
      </div>
      
    
  );
};

export default DepositData;
