import React from "react";
import styles from "./Dashboard.module.css";
import {
  AiOutlineHome,
  AiOutlineUser,
  AiOutlineWallet,
  AiOutlineLogout,
} from "react-icons/ai";
import { HiOutlineRefresh } from "react-icons/hi";
import { Link, withRouter } from "react-router-dom";
import { logout } from "../store/actions/AuthActions";
import { isAuthenticated } from "../store/selectors/AuthSelectors";
import { connect, useDispatch } from "react-redux";

import TranctionHistory from "../Resources/transaction_history_icon.svg";
import WithdrawalIcon from "../Resources/moneyBag.svg";

const LeftSideNav = (props) => {
  const dispatch = useDispatch();
  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logout(props.history));
  };

  const saved = localStorage.getItem("userDetails");
  const initialValue = JSON.parse(saved);
  const userDetails = initialValue && initialValue.response;

  var intials;
  var fullName = userDetails && userDetails[4];
  intials =
    fullName &&
    fullName
      .split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();

  return (
    <div className={styles.dashboardLeft}>
      <div style={{ paddingBottom: 20 }}>
        <div
          style={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            paddingTop: 30,
          }}
        >
          <div className={styles.userImage}>{intials}</div>
        </div>
        <div className={styles.username}>{userDetails && userDetails[4]}</div>
        <div className={`${styles.username} ${styles.userEmail}`}>
          {userDetails && userDetails[1]}
        </div>
      </div>
      <Link
        to="/"
        className={
          window.location.pathname === "/"
            ? styles.sideTabActive
            : styles.sideTab
        }
      >
        {" "}
        <AiOutlineHome className={styles.usericons} /> Home{" "}
      </Link>
      <Link
        to="/user-details"
        className={
          window.location.pathname === "/user-details"
            ? styles.sideTabActive
            : styles.sideTab
        }
      >
        {" "}
        <AiOutlineUser className={styles.usericons} /> User Details{" "}
      </Link>
      <Link
        to="/deposit"
        className={
          window.location.pathname === "/deposit"
            ? styles.sideTabActive
            : styles.sideTab
        }
      >
        {" "}
        <AiOutlineWallet className={styles.usericons} />
        Deposit{" "}
      </Link>
      <Link
        to="/withdrawal"
        className={
          window.location.pathname === "/withdrawal"
            ? styles.sideTabActive
            : styles.sideTab
        }
      >
        {" "}
        <img src={WithdrawalIcon} className={styles.usericons} />
        Withdrawal{" "}
      </Link>
      <Link
        to="/integrate-exchange"
        className={
          window.location.pathname === "/integrate-exchange"
            ? styles.sideTabActive
            : styles.sideTab
        }
      >
        {" "}
        <HiOutlineRefresh className={styles.usericons} />
        Exchange
      </Link>
      <Link
        to="/transaction-history"
        className={
          window.location.pathname === "/transaction-history"
            ? styles.sideTabActive
            : styles.sideTab
        }
      >
        {" "}
        <img src={TranctionHistory} className={styles.usericons} />
        Transaction History
      </Link>
      <Link to="#!" className={styles.sideTab} onClick={onLogout}>
        {" "}
        <AiOutlineLogout className={styles.usericons} />
        Logout
      </Link>

      <div className={styles.leftSideBottomContent}>
        Partners with Ansh Associates
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(LeftSideNav));
