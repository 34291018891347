import React, { useState, useEffect } from "react";
import styles from "../Login/login.module.css";
import styles2 from "../UserDetails/userDetails.module.css";

import { AiFillLock, AiOutlineUser } from "react-icons/ai";
import axios from "axios";
const ResetPassowrd = () => {
  const [password, setPassword] = useState("");
  const [username, setUserName] = useState("");
  const [confirmPassowrd, setConfirmPassword] = useState("");

  const [showMsg, setShowMsg] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [errorShow, setErrorShow] = useState(false);
  const handleSubmit = () => {
    if (password !== confirmPassowrd) {
      setErrorShow(true);
      setError("Password and Confirm Password does not match.");
    } else {
      setShow(true);
      let data = JSON.stringify({
        username: username,
        password: password,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://server.anshassociates.org/api/user/update-password",
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setShow(true);
          setShowMsg("Your password has been successfully updated.");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    if (show) {
      setShow(true);
      const toRef = setTimeout(() => {
        setShow(false);
        setPassword("");
        setConfirmPassword("");
        setUserName("");
        clearTimeout(toRef);
      }, 4000);
    }
  }, [show]);

  useEffect(() => {
    if (errorShow) {
      setErrorShow(true);
      const toRef = setTimeout(() => {
        setErrorShow(false);
        clearTimeout(toRef);
      }, 2000);
    }
  }, [errorShow]);
  return (
    <div className={`flex flex-col items-center justify-center min-h-screen w-full  px-6 md:px-10 lg:px-0`}>
      <div className={`${styles.resetloginBackground} w-full md:w-[550px] px-[20px] py-[20px] md:px-[40px] md:py-[40px]`}>
        <div className={`w-full text-center flex items-center justify-center text-[26px] lg:text-[56px] lg:leading-[58px] font-bold font-poppins text-[#ffffff]   `}>Ansh Associates</div>
        <div className={`text-center text-[20px] mt-5 lg:text-[28px] lg:leading-[24px] font-medium lg:mt-[30px] text-[#ffffff]`}>Reset your Password</div>
        <div className={`text-center text-[11px] mt-1 lg:text-[14px] lg:leading-[32px] font-normal lg:mt-[6px] text-[#ffffffcc]`}>
          Last 3 passwords are not allowed.
        </div>
        <div className={`flex items-center mt-[40px] bg-[#ffffff0a] rounded-[6px]`}>
          <AiOutlineUser
           className={`ml-4 w-5 h-5  text-[#FFCF2C]`}
          />
          <input
            type="text"
            value={username}
            onChange={(e) => setUserName(e.target.value)}
            placeholder="username"
            className={styles.logininput}
          />
        </div>
        <div className={`flex items-center mt-[40px] bg-[#ffffff0a] rounded-[6px]`}>
          <AiFillLock
           className={`ml-4 w-5 h-5  text-[#FFCF2C]`}
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="New password"
            className={styles.logininput}
          />
        </div>
        <div className={`flex items-center mt-[40px] bg-[#ffffff0a] rounded-[6px]`}>
          <AiFillLock
            className={`ml-4 w-5 h-5  text-[#FFCF2C]`}
          />
          <input
            type="password"
            value={confirmPassowrd}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="Confirm password"
            className={styles.logininput}
          />
        </div>
        {errorShow ? (
          <div className={`ml-4 ${styles.errorMsg}`}>{error}</div>
        ) : null}
        {show === true ? (
          <div className={styles2.successMsg}>{showMsg}</div>
        ) : null}
        <button className={`${styles.loginButton}  w-full`} onClick={handleSubmit}>
          {show === true ? "Saving ......." : "Reset Password"}
        </button>
      </div>
    </div>
  );
};

export default ResetPassowrd;
