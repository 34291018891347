import React from "react";
import styles from "../Home/Dashboard.module.css";
import {
  UseDepositHistoryData,
  UseWithdrawalHistoryData,
} from "../Utils/TransactionData";
const DashboardData = () => {
  const { data: depositDataHistory } = UseDepositHistoryData();
  const { data: withdrawalDataHistory } = UseWithdrawalHistoryData();

  const depositData = depositDataHistory && depositDataHistory.data.values;

  const withdrawalData =
    withdrawalDataHistory && withdrawalDataHistory.data.values;

  return (
    <div className="">
      <div className={`flex space-x-3 md:space-x-10 mt-4`}>
        <div className={`flex flex-col md:space-y-5 bg-[#17181b] border-[1px] border-[#ffffff]/20 rounded-[6px] px-[8px] py-[8px] md:px-[20px] md:py-[20px]`}>
          <div className={` text-[14px] text-[#ffffff]/80 font-medium font-poppins md:text-[20px] md:leading-[22px]`}>Total Deposited Amount</div>
          <div
            className={`text-[20px]  text-[#18b13f] font-semibold font-poppins md:text-[30px] md:leading-[22px] mt-2`}
          >
            +{" "}₮{depositData && depositData[0][3]}
          </div>
        </div>

        <div className={`flex flex-col lg:space-y-5 bg-[#17181b] border-[1px] border-[#ffffff]/20 rounded-[6px] px-[8px] py-[8px]  md:px-[20px] md:py-[20px]`}>
          <div className={`text-[14px] text-[#ffffff]/80 font-medium font-poppins md:text-[20px] md:leading-[22px]`}>Total Withdrawal Amount</div>
          <div
            className={`text-[20px]  text-[#eb323f] font-semibold font-poppins md:text-[30px] md:leading-[22px] mt-2 `}
          >
            -{" "}₮{withdrawalData && withdrawalData[0][3]}
          </div>
        </div>
      </div>
    </div>
  );
};
export default DashboardData;
