import React, { useState } from "react";
import styles from "./Dashboard.module.css";
import {
  UseTradeHistoryData,
  UseUsdtTradeHistoryData,
  UseMarqueeText,
} from "../Utils/TradeData";
import DashboardData from "./DashBoardData";
import TradeData from "./TradeData";
import Moment from "moment";
import { motion } from "framer-motion";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { FilterIcon, XIcon } from "lucide-react";

const style = {
  InputBox: "rounded-lg box-border border-[1px] border-solid border-[#d3d3ce] ",

  selectDiv:
    "flex items-center px-2 py-2  text-[14px] rounded-lg mx-3 md-3 text-neutral-300  hover:bg-[#ffcf2c]/10 hover:text-white/85  ease-linear duration-150 cursor-pointer",
};

const TradeHistory = () => {
  const { data: tradeHistory } = UseTradeHistoryData();
  const { data: usdtTradeHistory } = UseUsdtTradeHistoryData();
  const { data: marqueeText } = UseMarqueeText();
  const tradeData = tradeHistory && tradeHistory.data.values;
  const usdtTradeData = usdtTradeHistory && usdtTradeHistory.data.values;
  const marqueeDataText = marqueeText && marqueeText.data.values;

  const [filterData, setFilterData] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const saved = localStorage.getItem("userDetails");
  const initialValue = JSON.parse(saved);
  const userDetails = initialValue && initialValue.response;

  const filteredProfitTrades = (Data) => {
    const value = Data.filter((result) => {
      const item = result[8];
      return item > 1;
    });
    return value;
  };

  const filteredLossTrades = (Data) => {
    const value = Data.filter((result) => {
      const item = result[8];
      return item < 1;
    });
    return value;
  };

  const filteredTradesFromDate = (Data) => {
    const value = Data.filter((result) => {
      const item = new Date(result[0]);
      const start = Moment(String(item)).format("YYYY-MM-DD") >= startDate;
      return start;
    });
    return value;
  };

  const filteredTradesFromEndDate = (Data) => {
    const value = Data.filter((result) => {
      const item = new Date(result[0]);
      const end = Moment(String(item)).format("YYYY-MM-DD") <= endDate;
      return end;
    });
    return value;
  };

  const filteredTradesFromStartToEndDate = (Data) => {
    const value = Data.filter((result) => {
      const item = new Date(result[0]);
      const start = Moment(String(item)).format("YYYY-MM-DD") >= startDate;
      const end = Moment(String(item)).format("YYYY-MM-DD") <= endDate;
      const data = start && end;
      return data;
    });
    return value;
  };

  const filterAndSorted = (rawData) => {
    var raw = rawData;
    const sortedTradeHistory =
      raw && raw.slice().sort((a, b) => new Date(b[0]) - new Date(a[0]));
    var sData = [];
    var mData = [];
    if (filterData === "profit") {
      sData = filteredProfitTrades(raw);
    }
    if (filterData === "loss") {
      mData = filteredLossTrades(raw);
    }
    var newData = [...sData];
    var lossData = [...mData];
    newData =
      newData.length !== 0
        ? newData
        : lossData.length !== 0
        ? lossData
        : sortedTradeHistory;
    var sortedData = [];
    if (startDate) {
      sortedData = filteredTradesFromDate(newData);
    }
    if (endDate) {
      sortedData = filteredTradesFromEndDate(newData);
    }
    if (startDate && endDate) {
      sortedData = filteredTradesFromStartToEndDate(newData);
    }
    sortedData = sortedData.length === 0 ? newData : sortedData;
    return sortedData;
  };

  const [show, setShow] = useState(false);
  const [showUsdtTradeHistory, setShowUsdtTradeHistory] = useState("usdt");
  const [filterModal, setFilterModal] = useState(false);

  const openFilterModal = () => {
    setFilterModal(true);
  };

  const closeFilterModal = () => {
    setFilterModal(false);
  };

  const handleApplyFilter = () => {
    console.log("Filter Applied !!!");
    console.log("Start Date", startDate);
    console.log("End Date", endDate);

    if (showUsdtTradeHistory === "usdt") {
      filterAndSorted(usdtTradeData);
    } else if (showUsdtTradeHistory === "inr") {
      filterAndSorted(tradeData);
    }
    closeFilterModal();
  };

  return (
    <div className={` w-full px-[4px] py-[20px] md:px-[20px] md:py-[20px]   lg:px-[60px] lg:py-[20px] xl:px-[80px] xl:py-[20px]`}>
      {/* <marquee className={`absolute left-0 top-0 py-2  bg-[#ffcf2c1a] text-[24px] font-semibold text-[#ffffffd9] leading-[22px] hidden md:flex`}>
        {marqueeDataText && marqueeDataText[0]}
      </marquee> */}
      <div className={`flex mt-2`}>
        <div
          className={`flex flex-col  space-y-2 md:space-y-0  md:items-start text-[#f2f2f2] text-[22px] md:text-[30px] md:leading-[40px] font-normal font-poppins`}
        >
          <div>
            Welcome {userDetails && userDetails[4]} to Ansh Associates!!{" "}
          </div>
          <div
            className={`text-left text-[13px] lg:text-[14px] font-normal font-poppins text-[#f2f2f2]/60 leading-[22px]`}
          >
            Ansh Associates Dashboard for Profit & Loss Analysis.
          </div>
        </div>
      </div>
      <DashboardData />
      <div className="flex flex-row items-center  justify-between space-x-4 md:justify-start  md:space-x-8 border-b-[1px] border-[#ffffff]/20 md:border-none mt-[20px] md:mt-[40px] xl:mt-[20px]">
        <div
          className={
            showUsdtTradeHistory === "usdt"
              ? `text-[12px] text-[#ffffff] font-medium font-poppins  md:text-[18px] md:leading-[22px] border-b-2 py-2.5 border-[#FFCF2C] cursor-pointer `
              : `text-[12px] text-[#ffffff]/80 font-medium font-poppins  md:text-[18px] md:leading-[22px]  cursor-pointer`
          }
          onClick={() => {
            setShowUsdtTradeHistory("usdt");
            setFilterData("");
            setEndDate("");
            setStartDate("");
          }}
        >
          Trade History (USDT)
        </div>
        <div
          className={
            showUsdtTradeHistory === "inr"
              ? `text-[12px] text-[#ffffff] font-medium font-poppins  md:text-[18px] md:leading-[22px] border-b-2 py-2.5 border-[#FFCF2C] cursor-pointer `
              : `text-[12px] text-[#ffffff]/80 font-medium font-poppins  md:text-[18px] md:leading-[22px]  cursor-pointer `
          }
          onClick={() => {
            setShowUsdtTradeHistory("inr");
            setFilterData("");
            setEndDate("");
            setStartDate("");
          }}
        >
          Old Trade History (INR)
        </div>
        <button
          onClick={openFilterModal}
          className=" flex md:hidden items-center justify-center bg-[#f2c94c] py-1 px-2 rounded-md"
        >
          <FilterIcon strokeWidth={2} className="text-[#17181b] w-5 h-5 " />
        </button>
      </div>

      {/* Hidden on Mobile View  */}

      <div className={`hidden md:flex items-end mt-4 space-x-5 lg:space-x-10 w-full`}>
        <div className={`relative flex w-full md:w-[180px] lg:w-[220px]`}>
          <div
            onClick={() => setShow(!show)}
            className={`${styles.dropdownBox} md:w-[180px] lg:w-[220px] flex items-center justify-between  `}
          >
            {filterData === "profit" ? (
              <div className={styles.dropDownSelection}>Profit Trades</div>
            ) : filterData === "loss" ? (
              <div className={styles.dropDownSelection}>Loss Trades</div>
            ) : filterData === "view-all" ? (
              <div className={styles.dropDownSelection}>View All Trades</div>
            ) : (
              <div className={styles.dropDownSelection}>View All Trades</div>
            )}

            <div
              className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
            >
              {show ? (
                <AiFillCaretUp size={20} color="#fff" />
              ) : (
                <AiFillCaretDown size={20} color="#fff" />
              )}
            </div>
          </div>
          {show && (
            <motion.div
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.5, type: "spring" }}
              exit={{ y: -50, opacity: 0 }}
              className={`absolute right-0 top-13 bg-[#17181b] flex flex-col w-[100%] pt-2 pb-2 z-10 ${styles.dropdownBox2}`}
            >
              <div
                className={`${style.selectDiv} pl-3 font-medium`}
                onClick={() => {
                  setFilterData("view-all");
                  setShow(!show);
                }}
              >
                View All Trades{" "}
              </div>
              <div
                className={`${style.selectDiv} pl-3 font-medium`}
                onClick={() => {
                  setFilterData("profit");
                  setStartDate("");
                  setEndDate("");
                  setShow(!show);
                }}
              >
                Profit Trades
              </div>
              <div
                className={`${style.selectDiv} pl-3 font-medium`}
                onClick={() => {
                  setFilterData("loss");
                  setStartDate("");
                  setEndDate("");
                  setShow(!show);
                }}
              >
                Loss Trades
              </div>
            </motion.div>
          )}
        </div>
        <div className={`flex flex-col space-y-1`}>
          <label className="text-[#f2f2f2] mr-[10px] ">From : </label>
          <input
            key="min"
            type="date"
            className={`${styles.inputDate} w-[140px] lg:w-[190px]`}
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>
        <div className={`flex flex-col space-y-1`}>
        <label className="text-[#f2f2f2] mr-[10px] ">To : </label>
          <input
            key="max"
            type="date"
            className={`${styles.inputDate} w-[140px] lg:w-[190px]`}
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className={`w-full flex items-center `}>
          <button
            className=" text-black bg-[#FFCF2C] py-2.5 px-[30px] rounded-[6px] font-semibold text-[18px] font-poppins"
            onClick={() => {
              setStartDate("");
              setEndDate("");
            }}
          >
            Clear
          </button>
        </div>
      </div>

      {/* Visible on Mobile View  Only */}

      {filterModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50 px-4 lg:px-0 ">
          <div className="relative w-full lg:w-[440px] ">
            <div
              className="absolute right-2 top-4  text-[#000000]/80 font-bold hover:text-[#ff0000] cursor-pointer "
              onClick={closeFilterModal}
            >
              <XIcon className="w-6 h-6 mr-2 text-[#ffffff]" />
            </div>
            <div className=" w-full px-8 py-16 lg:w-[440px] bg-[#17181b] border-[#ffffff]/20 rounded-md">
              <div className={`flex flex-col items-center space-y-6 w-full`}>
                <div className={`relative w-full `}>
                  <div
                    onClick={() => setShow(!show)}
                    className={`${styles.dropdownBox}  flex justify-between items-center w-full `}
                  >
                    {filterData === "profit" ? (
                      <div className={`${styles.dropDownSelection} `}>
                        Profit Trades
                      </div>
                    ) : filterData === "loss" ? (
                      <div className={`${styles.dropDownSelection} `}>
                        Loss Trades
                      </div>
                    ) : filterData === "view-all" ? (
                      <div className={`${styles.dropDownSelection} `}>
                        View All Trades
                      </div>
                    ) : (
                      <div className={`${styles.dropDownSelection} `}>
                        View All Trades
                      </div>
                    )}

                    <div
                      className={`pl-2 ease-linear duration-150 text-white cursor-pointer`}
                    >
                      {show ? (
                        <AiFillCaretUp size={20} color="#fff" />
                      ) : (
                        <AiFillCaretDown size={20} color="#fff" />
                      )}
                    </div>
                  </div>
                  {show && (
                    <motion.div
                      initial={{ y: -50, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      transition={{ duration: 0.5, type: "spring" }}
                      exit={{ y: -50, opacity: 0 }}
                      className={`absolute right-0 top-16 bg-[#17181b] flex flex-col w-[100%] pt-2 pb-2 z-10 ${styles.dropdownBox2}`}
                    >
                      <div
                        className={`${style.selectDiv} pl-3 font-medium`}
                        onClick={() => {
                          setFilterData("view-all");
                          setShow(!show);
                        }}
                      >
                        View All Trades{" "}
                      </div>
                      <div
                        className={`${style.selectDiv} pl-3 font-medium`}
                        onClick={() => {
                          setFilterData("profit");
                          setStartDate("");
                          setEndDate("");
                          setShow(!show);
                        }}
                      >
                        Profit Trades
                      </div>
                      <div
                        className={`${style.selectDiv} pl-3 font-medium`}
                        onClick={() => {
                          setFilterData("loss");
                          setStartDate("");
                          setEndDate("");
                          setShow(!show);
                        }}
                      >
                        Loss Trades
                      </div>
                    </motion.div>
                  )}
                </div>
                <div className={` w-full flex flex-col space-y-2 `}>
                  <label style={{ color: "#f2f2f2", marginRight: 10 }}>
                    From :{" "}
                  </label>
                  <input
                    key="min"
                    type="date"
                    className={styles.inputDate}
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                  />
                </div>
                <div className={`w-full flex flex-col space-y-2`}>
                  <label style={{ color: "#f2f2f2", marginRight: 10 }}>
                    To :{" "}
                  </label>
                  <input
                    key="max"
                    type="date"
                    className={`${styles.inputDate} w-full`}
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                  />
                </div>
                <div
                  className={`pt-6 w-full flex items-center space-x-6 justify-between`}
                >
                  <button
                    className=" w-full text-white border-[1px] border-[#ffffff]/20 py-1.5 px-[30px] rounded-md font-semibold text-[18px] font-poppins"
                    onClick={() => {
                      setStartDate("");
                      setEndDate("");
                    }}
                  >
                    Clear
                  </button>

                  <button
                    className=" w-full text-black bg-[#FFCF2C] py-1.5 px-[30px] rounded-md font-semibold text-[18px] font-poppins"
                    onClick={handleApplyFilter}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showUsdtTradeHistory === "usdt" ? (
        <TradeData
          tradeData={filterAndSorted(usdtTradeData && usdtTradeData)}
        />
      ) : (
        <TradeData tradeData={filterAndSorted(tradeData && tradeData)} />
      )}
    </div>
  );
};

export default TradeHistory;
