import React from "react";
import styles from "./Dashboard.module.css";
import Moment from "moment";

const TradeData = ({ tradeData }) => {
  return (
    <div>
      {!tradeData && !tradeData ? (
        <div className="flex items-center justify-center  border-[2px] rounded-lg  border-[#ffffff]/20  w-full h-[calc(100vh-260px)] md:h-[calc(100vh-420px)] lg:h-[calc(100vh-620px)]   xl:h-[calc(100vh-420px)] text-white  text-4xl md:mt-10 xl:mt-4 ">
          No Data Available 
        </div>
      ) : tradeData && tradeData.length === 0 ? (
        <div className="flex items-center justify-center  border-[2px] rounded-lg  border-[#ffffff]/20  w-full h-[calc(100vh-260px)] md:h-[calc(100vh-420px)] lg:h-[calc(100vh-620px)]   xl:h-[calc(100vh-420px)] text-white  text-4xl md:mt-10 xl:mt-4 ">
          No Data Available 
        </div>
      ) : (
        <div className="w-full h-[calc(100vh-260px)] md:h-[calc(100vh-420px)] lg:h-[calc(100vh-620px)]   xl:h-[calc(100vh-420px)] overflow-auto  custom-scroll-sidebar  mt-8 md:mt-10  xl:mt-4 lg:pr-2">
          <table className={`w-full  `}>
            <thead className="bg-[#17181b]  ">
              <tr className=" ">
                <th className=" min-w-[130px] text-[#f2c94c]   text-[13px] border-[1px]  border-[#ffffff]/20  font-poppins font-medium text-left py-3 px-4 lg:px-8">
                  DATE
                </th>
                <th className="  text-[#f2c94c]  text-[13px] border-[1px]  border-[#ffffff]/20  font-poppins font-medium py-3 px-3 lg:px-2">
                  SYMBOL
                </th>
                <th className="  text-[#f2c94c]   text-[13px] border-[1px]  border-[#ffffff]/20  font-poppins font-medium py-3 px-3 lg:px-2">
                  POSITION
                </th>
                <th className=" min-w-[130px] text-[#f2c94c]    text-[13px] border-[1px]  border-[#ffffff]/20 font-poppins font-medium py-3 px-3 lg:px-2">
                  ENTRY POINT
                </th>

                <th className=" min-w-[130px] text-[#f2c94c]  text-[13px] border-[1px]  border-[#ffffff]/20  font-poppins font-medium py-3 px-3 lg:px-2">
                  QUANTITY
                </th>

                <th className=" min-w-[130px] text-[#f2c94c]  text-[13px] border-[1px]  border-[#ffffff]/20  font-poppins font-medium py-3 px-3 lg:px-2">
                  EXIT POINT
                </th>

                <th className="  min-w-[130px] text-[#f2c94c]  text-[13px] border-[1px]  border-[#ffffff]/20  font-poppins font-medium py-3 px-3 lg:px-2">
                  QUANTITY
                </th>

                <th className=" min-w-[100px] md:min-w-[100px] text-[#f2c94c]  text-[13px] border-[1px]  border-[#ffffff]/20  font-poppins font-medium py-3 px-3 lg:px-2">
                  PNL VALUE
                </th>

                <th className=" min-w-[100px] text-[#f2c94c]  text-[13px] border-[1px]  border-[#ffffff]/20  font-poppins font-medium py-3 px-3 lg:px-5">
                  PNL (in %)
                </th>
              </tr>
            </thead>
            <tbody>
              {tradeData &&
                tradeData?.map((dataItem, i) => {
                  const item = dataItem[7];
                  const checkValue = Math.sign(item);
                  const pnlPercenet =
                    dataItem[8] && dataItem[8].replace("%", "");
                  const pnlPercenetValue = Math.sign(pnlPercenet);

                  return (
                    <tr key={i} className="border-[1px]   border-[#ffffff]/20">
                      <td className="text-[12px] border-r-[1px]  border-[#ffffff]/20 text-[#f2f2f2] font-poppins font-normal text-left py-3  px-3 ">
                        {Moment(dataItem[0], "DD/MM/YYYY").format("DD-MM-YYYY")}
                      </td>
                      <td className="text-[12px] border-r-[1px]  border-[#ffffff]/20 text-[#f2f2f2] font-poppins font-normal text-center py-3 px-2 ">
                        {dataItem[1]}
                      </td>
                      <td className="text-[12px] border-r-[1px]  border-[#ffffff]/20 text-[#f2f2f2] font-poppins font-normal text-center py-3 px-2 ">
                        {dataItem[2]}
                      </td>
                      <td className="text-[12px] border-r-[1px]  border-[#ffffff]/20 text-[#f2f2f2] font-poppins font-normal text-center py-3 px-2 ">
                        {dataItem[3]}
                      </td>
                      <td className="text-[12px] border-r-[1px]  border-[#ffffff]/20 text-[#f2f2f2] font-poppins font-normal text-center py-3 px-2 ">
                        {dataItem[4]}
                      </td>
                      <td className="text-[12px] border-r-[1px]  border-[#ffffff]/20 text-[#f2f2f2] font-poppins font-normal text-center py-3 px-2 ">
                        {dataItem[5]}
                      </td>
                      <td className="text-[12px] border-r-[1px]  border-[#ffffff]/20 text-[#f2f2f2] font-poppins font-normal text-center py-3 px-2 ">
                        {dataItem[6]}
                      </td>
                      <td
                        className={`text-[12px] border-r-[1px]  border-[#ffffff]/20 text-[#f2f2f2] font-poppins font-normal text-center py-3 px-2 
                      ${
                        parseFloat(item) > 0
                          ? `text-[#18b13f]`
                          : parseFloat(item) < 0
                          ? ` text-[#eb323f]`
                          : `text-[#f2f2f2]`
                      }
                      `}
                      >
                        {dataItem[7]}
                      </td>
                      <td
                        className={`text-[12px] border-r-[1px]  border-[#ffffff]/20 text-[#f2f2f2] font-poppins font-normal text-center py-3 px-2 
                      ${
                        pnlPercenetValue === 1
                          ? `text-[#18b13f]`
                          : pnlPercenetValue === -1
                          ? `text-[#eb323f]`
                          : `text-[#f2f2f2]`
                      }
                      `}
                      >
                        {dataItem[8]}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TradeData;
