import axios from "axios";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";

export function login(username, password) {
  const postData = {
    username,
    password,
  };
  return axios.post(
    `https://server.anshassociates.org/api/user/login`,
    postData
  );
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("userDetails", JSON.stringify(tokenDetails));
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("userDetails");
  let tokenDetails = "";
  if (!tokenDetailsString) {
    dispatch(logout(history));
    return;
  }
  if (tokenDetailsString) {
    tokenDetails = JSON.parse(tokenDetailsString);
  }
  dispatch(loginConfirmedAction(tokenDetails));
}
