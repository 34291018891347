import { useQuery, useQueryClient } from "react-query";
import axios from "axios";

const fetchDashboard = () => {
  return axios.get(`https://server.anshassociates.org/api/user/data/dashboard`);
};

export const UseDashboarddata = (heroId) => {
  const queryClient = useQueryClient();
  return useQuery(["user-hero", heroId], fetchDashboard, {
    initialData: () => {
      const data = queryClient
        .getQueryData("user-heroes")
        ?.data?.find((data) => data.id === parseInt(heroId));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};
