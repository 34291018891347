import React from "react";
import DashboardLayout from "./DashboardLayout";

export default function SettingsPage() {


  return (
    <DashboardLayout>
      <div className="flex lg:min-h-screen w-full items-center justify-center text-black bg-red-500">
        This is Settigs.
      </div>
    </DashboardLayout>
  );
}


// "react-router-dom": "^5.3.3"