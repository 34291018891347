import React from "react";
import styles from "../Home/Dashboard.module.css";
import { withRouter } from "react-router-dom";
import { isAuthenticated } from "../store/selectors/AuthSelectors";
import { connect } from "react-redux";
import { QueryClientProvider, QueryClient } from "react-query";
import TransactionHistoryData from "./TransactionHistotyData";
import DashboardLayout from "../DashboardLayout"

const queryClient = new QueryClient();

const TransactionHistory = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <DashboardLayout>
        <div className={`bg-gradient-to-b from-[#000000]/5 to-[#231c04] flex flex-col xl:min-h-screen w-full lg:items-center justify-center px-3 lg:px-0`}>
          <TransactionHistoryData />
        </div>
      </DashboardLayout>
    </QueryClientProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(TransactionHistory));
