import React from "react";
import { Link, withRouter } from "react-router-dom";
import { logout } from "./store/actions/AuthActions";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import { connect, useDispatch } from "react-redux";
import { cn } from "./Utils/utils";

import {
  LogOutIcon,
  WalletIcon,
  RefreshCcwIcon,
  FileClockIcon,
  UserIcon,
  IndianRupeeIcon,
  HomeIcon,
  XIcon,
} from "lucide-react";

const Icons = {
  home: <HomeIcon className="mr-5 h-5 w-5 md:h-7 md:w-7" />,
  user: <UserIcon className="mr-5 h-5 w-5 md:h-7 md:w-7" />,
  deposit: <WalletIcon className="mr-5 h-5 w-5 md:h-7 md:w-7" />,
  withdrawal: <IndianRupeeIcon className="mr-5 h-5 w-5 md:h-7 md:w-7" />,
  exchange: <RefreshCcwIcon className="mr-5 h-5 w-5 md:h-7 md:w-7" />,
  history: <FileClockIcon className="mr-5 h-5 w-5 md:h-7 md:w-7" />,
};

const mobileNavItems = [
  {
    title: "Home",
    href: "/",
    path: "/",
    icon: "home",
  },
  {
    title: "User Details",
    href: "/user-details",
    path: "user-details",
    icon: "user",
  },
  {
    title: "Deposit",
    href: "/deposit",
    path: "deposit",
    icon: "deposit",
  },
  {
    title: "Withdrawal",
    href: "/withdrawal",
    path: "withdrawal",
    icon: "withdrawal",
  },
  {
    title: "Exchange",
    href: "/integrate-exchange",
    path: "integrate-exchange",
    icon: "exchange",
  },
  {
    title: "Transaction History",
    href: "/transaction-history",
    path: "transaction-history",
    icon: "history",
  },
];

const MobileNavBar = ({ isOpen, onClose }, props) => {
  const path = window.location.pathname;

  const dispatch = useDispatch();

  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logout(props.history));
  };

  const saved = localStorage.getItem("userDetails");
  const initialValue = JSON.parse(saved);
  const userDetails = initialValue && initialValue.response;

  var intials;
  var fullName = userDetails && userDetails[4];
  intials =
    fullName &&
    fullName
      .split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();

  if (!mobileNavItems?.length) {
    return null;
  }

  return (
    <div
      className={`fixed inset-0 z-50 ${
        isOpen ? "block " : "hidden"
      } transition ease-in duration-300`}
    >
      {/* Background Overlay */}
      <div
        className={`fixed inset-0 bg-transparent opacity-40  ${
          isOpen ? "bg-opacity-100" : "bg-opacity-0"
        }`}
        onClick={onClose}
      ></div>

      {/* Drawer */}
      <div
        className={`fixed inset-y-0 left-0 w-full bg-black z-50 transform transition ease-in duration-500   ${
          isOpen ? "translate-x-0 animate-slideInNav" : "-translate-x-full "
        }`}
      >
        <aside
          className={`flex flex-col  transform top-0 left-0 w-full bg-black fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30   ${
            isOpen
              ? "translate-x-0 animate-slideInNav"
              : "-translate-x-full animate-slideOutNav"
          }`}
        >
          <div onClick={onClose} className={`absolute   z-100 top-4 right-2`}>
            <XIcon className="h-6 w-6 md:h-12 md:w-12 text-white" />
          </div>
          <div className="px-2 mb-2 lg:mb-12">
            <div className="flex flex-row space-x-6 items-center px-6 py-5 md:py-10">
              <div className="text-[22px] md:text-[38px] font-medium font-poppins text-[#f2f2f2] leading-[22px] ">
                Ansh Associates
              </div>
            </div>
          </div>
          <div className="flex flex-col  border-t-[1px] border-[#ffffff]/20 w-full px-8 py-3">
            <div className="flex   items-center justify-center w-full ">
              <div className="flex flex-col space-y-3 items-center   text-white text-center   ">
                <div className="flex  font-medium text-2xl md:text-[46px] items-center justify-center rounded-full border-[2px] border-[#ffffff]/30 w-[70px] h-[70px] md:w-[100px] md:h-[100px] p-1">
                  {intials}
                </div>
                <div className="flex flex-col items-center">
                  <span className="text-[18px] md:text-[24px] text-[#F2F2F2] font-poppins font-medium">
                    {userDetails && userDetails[4]}
                  </span>
                  <span className="text-[14px] md:text-[18px] text-[#D9D9D9]/40 font-poppins font-normal mt-1">
                    {userDetails && userDetails[1]}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col flex-1 gap-y-1.5 mt-3 md:mt-8">
            {mobileNavItems.map((item, index) => {
              const Icon = Icons[item.icon];

              return (
                item.href && (
                  <div key={index}>
                    <Link key={index} to={item.disabled ? "/" : item.href}>
                      <div
                        className={cn(
                          "group  lg:py-5 text-sm md:text-[24px] border-l-4 md:border-l-8  hover:bg-[#ffcf2c1a] hover:text-gray-300 text-[18px] text-[#ffffff]/50 font-normal font-poppins leading-[22px] flex items-center px-[30px] py-[14px] md:px-[50px] md:py-[20px]",
                          path === item.href
                            ? "bg-[#ffcf2c1a] border-l-4 md:border-l-8 border-[#ffcf2c] text-[#ffffff]"
                            : "border-l-4 md:border-l-8 border-transparent bg-transparent",
                          item.disabled && "cursor-not-allowed opacity-80"
                        )}
                      >
                        {Icon}
                        <span>{item.title}</span>
                      </div>
                    </Link>
                  </div>
                )
              );
            })}
            <div
              onClick={onLogout}
              className={cn(
                "group cursor-pointer text-sm md:text-[24px]  border-l-4 md:border-l-8 border-transparent bg-transparent hover:bg-[#ffcf2c1a] hover:text-gray-300 text-[18px] text-[#ffffff]/50 font-normal font-poppins leading-[22px] flex items-center px-[30px] py-[14px] md:px-[50px] md:py-[20px]"
              )}
            >
              <LogOutIcon className=" h-5 w-5 md:h-7 md:w-7 text-[#ffffff]/50 group-hover:text-gray-300  mr-5" />
              <span>Logout</span>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(MobileNavBar));
