import React, { useEffect } from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import Login from "./Login/login";
import ForgotPassowrd from "./Login/forgotPassword";
import ResetPassowrd from "./Login/ResetPassword";
import Dashboard from "./Home/DashBoard";
import UserDetails from "./UserDetails/userDetails";
import ExchangeIntegrate from "./Home/ExchnageIntegrate";
import Deposit from "./UserDetails/Deposit";
import Withdrawal from "./UserDetails/Withdrawal";
import { connect, useDispatch } from "react-redux";
import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import TransactionHistory from "./TransactionHistory/TransactionHistory";
import SettingsPage from "./Settings";

function App(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    checkAutoLogin(dispatch);
  }, [dispatch]);

  return (
    <div className="App">
      <Switch>
        <Route
          exact
          path="/"
          // component={Dashboard}
          component={() => (props.isAuthenticated ? <Dashboard /> : <Login />)}
        />
        <Route
          path="/user-details"
          component={() =>
            props.isAuthenticated ? <UserDetails /> : <Login />
          }
        />
        <Route
          path="/deposit"
          component={() => (props.isAuthenticated ? <Deposit /> : <Login />)}
        />
        <Route
          path="/settings"
          component={() => (props.isAuthenticated ? <SettingsPage /> : <Login />)}
        />
        <Route
          path="/withdrawal"
          component={() => (props.isAuthenticated ? <Withdrawal /> : <Login />)}
        />
        <Route
          path="/integrate-exchange"
          component={() =>
            props.isAuthenticated ? <ExchangeIntegrate /> : <Login />
          }
        />
        <Route
          path="/transaction-history"
          component={() =>
            props.isAuthenticated ? <TransactionHistory /> : <Login />
          }
        />
        <Route exact path="/forgot-password" component={ForgotPassowrd} />
        <Route exact path="/reset-password" component={ResetPassowrd} />
      </Switch>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default connect(mapStateToProps)(App);
