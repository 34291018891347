import React from "react";
import styles from "../Home/Dashboard.module.css";
import Moment from "moment";

const TransactionData = ({ tradeData }) => {
  return (
    <div>
      {tradeData && tradeData.length === 0 ? (
        <div className="flex items-center justify-center  border-[2px] rounded-lg  border-[#ffffff]/20  w-full h-[calc(100vh-260px)] md:h-[calc(100vh-420px)] lg:h-[calc(100vh-620px)]   xl:h-[calc(100vh-420px)] text-white  text-4xl mt-4 ">
          No Data Available
        </div>
      ) : (
        <div className="w-full h-[calc(100vh-260px)]  md:h-[calc(100vh-420px)] lg:h-[calc(100vh-620px)]   xl:h-[calc(100vh-420px)]   overflow-auto custom-scroll-sidebar mt-4 lg:pr-2">
          <table className={`w-full  `}>
            <thead className="bg-[#17181b]  ">
              <tr className=" ">
                <th className=" min-w-[130px] text-[#f2c94c]  text-[13px] border-[1px]  border-[#ffffff]/20  font-poppins font-medium text-left py-3 px-4 lg:px-8">
                  DATE
                </th>
                <th className=" min-w-[130px] text-[#f2c94c]  text-[13px] border-[1px]  border-[#ffffff]/20  font-poppins font-medium py-3 lg:px-5">
                  CURRENCY
                </th>
                <th className=" min-w-[130px] text-[#f2c94c]   text-[13px] border-[1px]  border-[#ffffff]/20  font-poppins font-medium py-3 lg:px-5">
                  NETWORK
                </th>
                <th className=" min-w-[130px] text-[#f2c94c]    text-[13px] border-[1px]  border-[#ffffff]/20  font-poppins font-medium py-3 lg:px-5">
                  AMOUNT
                </th>

                <th className=" min-w-[130px] text-[#f2c94c]  text-[13px] border-[1px]  border-[#ffffff]/20  font-poppins font-medium py-3 lg:px-5">
                  WALLET
                </th>
              </tr>
            </thead>
            <tbody>
              {tradeData &&
                tradeData?.map((dataItem, i) => {
                  return (
                    <tr
                      key={i}
                      className="border-[1px]   border-[#ffffff]/20"
                    >
                      <td className="text-[12px] border-r-[1px]  border-[#ffffff]/20 text-[#f2f2f2] font-poppins font-normal text-left py-3  px-4 ">
                        {Moment(dataItem[7], "DD/MM/YYYY").format("DD-MM-YYYY")}
                      </td>
                      <td className="text-[12px] border-r-[1px]  border-[#ffffff]/20 text-[#f2f2f2] font-poppins font-normal text-center py-3 px-2 ">
                        {dataItem[6]}
                      </td>
                      <td className="text-[12px] border-r-[1px]  border-[#ffffff]/20 text-[#f2f2f2] font-poppins font-normal text-center py-3 px-2 ">
                        {dataItem[5]}
                      </td>
                      <td className="text-[12px] border-r-[1px]  border-[#ffffff]/20 text-[#f2f2f2] font-poppins font-normal text-center py-3 px-2 ">
                        {dataItem[3]}
                      </td>
                      <td className="text-[12px] border-r-[1px]  border-[#ffffff]/20 text-[#f2f2f2] font-poppins font-normal text-center py-3 px-2 ">
                        {dataItem[4]}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default TransactionData;
