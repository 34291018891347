import { useQuery, useQueryClient } from "react-query";
import axios from "axios";

const fetchDepositHistory = () => {
  return axios.get(
    `https://server.anshassociates.org/api/user/deposit/dashboard`
  );
};

const fetchWithdrwalHistory = () => {
  return axios.get(
    `https://server.anshassociates.org/api/user/withdrawal/dashboard`
  );
};

const fetchDepositHistoryDetails = () => {
  return axios.get(
    `https://server.anshassociates.org/api/user/deposit/dashboard/history`
  );
};

const fetchWithdrwalHistoryDetails = () => {
  return axios.get(
    `https://server.anshassociates.org/api/user/withdrawal/dashboard/history`
  );
};

export const UseDepositHistoryData = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(["depositDataHistory", hero], fetchDepositHistory, {
    initialData: () => {
      const data = queryClient
        .getQueryData("trade-heroes")
        ?.data?.find((data) => data.id === parseInt(hero));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseWithdrawalHistoryData = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(["withdrawalDataHistory", hero], fetchWithdrwalHistory, {
    initialData: () => {
      const data = queryClient
        .getQueryData("trade-heroes")
        ?.data?.find((data) => data.id === parseInt(hero));
      if (data) {
        return { data: data };
      } else {
        return undefined;
      }
    },
  });
};

export const UseDepositHistoryDetailsData = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["depositDataHistoryDetails", hero],
    fetchDepositHistoryDetails,
    {
      initialData: () => {
        const data = queryClient
          .getQueryData("trade-heroes")
          ?.data?.find((data) => data.id === parseInt(hero));
        if (data) {
          return { data: data };
        } else {
          return undefined;
        }
      },
    }
  );
};

export const UseWithdrawalHistoryDetailsData = (hero) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["withdrawalDataHistoryDetails", hero],
    fetchWithdrwalHistoryDetails,
    {
      initialData: () => {
        const data = queryClient
          .getQueryData("trade-heroes")
          ?.data?.find((data) => data.id === parseInt(hero));
        if (data) {
          return { data: data };
        } else {
          return undefined;
        }
      },
    }
  );
};
